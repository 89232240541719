import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { useState } from "react";
import HeaderComponent from "../components/HeaderComponent";
import CompanySetting from "../features/account/company-setting";
import HomePageSegment from "../features/web-pages/home-page";
import { useTabStyles } from "../utils/styles-util";

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BASE_PATH = "web-pages";

export default function ClientSegment({ location }) {
  const classes = useStyles();
  const tabsStyles = useTabStyles();
  const { pathname } = location;
  const [value, setValue] = useState(() => {
    return pathname.indexOf("company-setting") !== -1 ? 1 : 0;
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 0 && navigate(`${BASE_PATH}/`);
    newValue === 1 && navigate(`${BASE_PATH}/company-setting`);
  };

  return (
    <div className="sharing-referral-page account-page">
      <HeaderComponent
        headerContent={
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            textColor="inherit"
            classes={{
              root: tabsStyles.tabs,
              flexContainer: tabsStyles.tabsFlexContainer,
              indicator: tabsStyles.indicator,
            }}
          >
            <Tab
              label="Home Page"
              {...a11yProps(0)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
            <Tab
              label="Team Page"
              disabled
              {...a11yProps(1)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
            <Tab
              label="Digital Fact Find"
              disabled
              {...a11yProps(2)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
            <Tab
              label="Meta Data & Tracking Codes"
              disabled
              {...a11yProps(3)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
          </Tabs>
        }
      />
      <div className="account-page__main-container">
        <Router basepath={BASE_PATH}>
          <HomePageSegment path="/*" />
          <CompanySetting path="/company-setting" />
        </Router>
      </div>
    </div>
  );
}
