import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import UploadImageComponent from "../../../../components/upload-image";

const UploadImageField = ({ name, imageURL }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        return (
          <div className="upload-image-field">
            <input {...field} />
            <UploadImageComponent
              imageURL={imageURL}
              handleSetCroppedImage={field.onChange}
            />
            <ErrorMessage
              className="error-message"
              errors={errors}
              name={name}
              as={<p />}
            />
          </div>
        );
      }}
      name={name}
      rules={{ required: "This field is required" }}
      control={control}
    />
  );
};

export default UploadImageField;
