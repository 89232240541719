import { useCompanyLogo } from "../../hooks/company.hooks";
import "./header-component.scss";

const HeaderComponent = ({ headerContent, className = "" }) => {
  const { darkLogo } = useCompanyLogo();
  return (
    <div className={`common-header ${className}`}>
      <div className="common-header__inner-container">
        {headerContent || <div />}
        {darkLogo && (
          <div className="header-logo">
            <img alt="Experity-site-logo" src={darkLogo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
